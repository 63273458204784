/* quick fix page for rti */
.rti-bg_container {
    position: absolute;
    top: 10rem;
    width: 100%;
    z-index: -1;
}

.rti-header{
    background-image: none!important;
}

.counter{
    // height: 50px;
    width: max-content;
    position: relative;
    // bottom: 4rem;
    // right: -97%;
    height: 30px;
    bottom: -3rem;
    right: -90%;
    // width: 50px;
    &-container{
        width: max-content;
    }
    img{
        width: 100%;
        height: 100%;
    }
}

/* quick fix page for rti end*/