// .u-center-text { text-align: center !important; }

//style for background image of sections
.section-background-image{ 
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.margin-initial{
    margin: initial;
}