.home-explore-block{
    &-heading{
        // height: 50%;
        height: 16rem;
        
        @include respond(tab-small){
            // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
                // font-size: 4rem;
                height: 9rem;
        
        }
    }

    &-logo-block{
        position: relative;
        height: 17.5rem;        
        @include respond(tab-small){
            // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
                // font-size: 4rem;
                height: 13rem;
        
        }
        &-logo{
            @include absCenter;
        }
    }

}