.footer-base-links{
    position: relative;
    height: 11.6rem;
    font-family: $Montserrat;
    // font-size: $footer-links-font-size;
    margin: 2.5rem 0;
    // vertical-align: middle;
    @media only screen and (max-device-width: 1100px) { 
        height: 8rem;//for now
    }; 

    @include respond(tab-port){
    // @media only screen and (max-width: 600px) {     //37.5em,  600px
    
        // width: 8rem;
        height: 8rem;
    }
    &-each-link{
        display: inline-block;
        cursor: pointer;
        // vertical-align: middle;
        // height: 10rem;        
        padding:0 2rem;
        & > a {
            color:$footer-feed-label-color;        
        }
        font-size: $footer-links-font-size;
        // @include respond(tab-port){
        //     // transform: translate(-20%, -50%);     
        //     font-size: 1.4rem;           
        // }                

        @media only screen and (max-device-width: 1200px) { //remove later 
            padding:0 1rem;
            font-size: 1.2rem;
        
        }; 
    
        @include respond(tab-port){ //remove later
      
            padding:0 1rem;
            
        }
    }

    &-left-links-block, &-right-links-block, &-center{
        display: inline-block
    }

    &-center{
        // text-align: center;
        @include absCenter;        
        width: 11.6rem;
        height: 11.6rem;
        @media only screen and (max-device-width: 1100px) { 
            height: 8rem;//for now
            width: 8rem;       
        }; 
        @include respond(tab-port){
        // @media only screen and (max-width: 600px) {     //37.5em,  600px
    
            width: 8rem;
            height: 8rem;
        }
    }

    &-left-links-block{
        // text-align: right;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(-22%, -50%);
        @media only screen and (max-device-width: 1100px) { 
            // height: 8rem;//for now
            // width: 8rem;       
            transform: translate(-15%, -50%);        
        
        }; 
        @include respond(tab-port){
            transform: translate(-15%, -50%);        
        }        
    }

    &-right-links-block{
        // text-align: left;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(19%, -50%);
        @media only screen and (max-device-width: 1100px) { 
            // height: 8rem;//for now
            // width: 8rem;       
            // transform: translate(-20%, -50%);        
            transform: translate(12%, -50%);                
        }; 
        @include respond(tab-port){
            transform: translate(12%, -50%);        
        }        
    }

    &-logo{
        // @include absCenter;
        width: 11.6rem;
        height: 11.6rem;
        z-index: 0;
        @media only screen and (max-device-width: 1100px) { 
            height: 8rem;//for now
            width: 8rem;       
            // transform: translate(-20%, -50%);        
            // transform: translate(12%, -50%);                
        }; 
        @include respond(tab-port){
        // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
        
            width: 8rem;
            height: 8rem;
        }
    }
}