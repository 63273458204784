.page-section{
    position: relative;
    display: block;
    width: 100%;
    &-background{
        position: absolute;
        background-size: cover;
        background-size: cover;
        width: 100%;
        // height: 100%;
        top: 0;
        z-index: -1;
    }
}

.text-highlight{
    color: white;
    font-weight: bolder;
}

.right-to-info{
    &-background{
        background-image: url(../../../assets/img/about_us/background_image/chimpanzee.png)!important ;   
        z-index: initial;
        height: initial;
    }

    &-content{
        padding-right: 7.8rem;
        @include respond(tab-port){
        // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
            // font-size: 4rem;
            padding-right: 7.8rem;
            
        }

        &-font-size{
            font-size: 18px;
            line-height: 1.78;
        }
    }

    &-button-text{
        @include absCenter;
        top: 10%;
    }
}




.about-zack-block{
    &-heading {
        font-size: $font-size-x-large;
        margin: initial;
        @include respond(tab-port){
        // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
            font-size: 4rem;
        }
    }
    &-secondary-header-block{
        margin: 4.5rem 0;
        & > h4 {
            font-size: 25px;        
        }

        & > h6 {
            font-size: 18px;
        }
        @include respond(tab-port){
        // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
            // font-size: 4rem;
            margin: 2rem 0;
 
        }
    }

    &-para{
        width: 45%;
        // height: 210px;
        // mix-blend-mode: undefined;
        font-family: $Montserrat;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: #252525;
        margin: auto;

        @include respond(tab-port){
            // @media only screen and (max-device-width: 600px) {     //37.5em,  600px
                // font-size: 4rem;
                width: 60%;
                font-size: 14px;
        }
    }
}
