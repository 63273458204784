// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";

//base styles, common across pages
@import "base/app-fonts.scss";
@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";

//layouts, common layouts used across pages
@import "layout/footer-base-links";
@import "layout/footer-block";

//pages
@import "pages/about-us";
@import "pages/get-involved";
@import "pages/home";
@import "pages/rti";
@import "pages/news";





.test-sass{
    width: 100%;
}