// COLORS
$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;

$footer-links-font-size: 18px;//1.8rem;//probably need 1.4, 1.8 according to UI

$font-size-large : 3.4rem;

$font-size-x-large:48px;

$font-size-small : 1.4rem;
// GRID

$footer-feed-label-color :#ececec
