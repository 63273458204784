
$JotiOne : 'JotiOne';
$Montserrat : 'Montserrat';


@font-face {
    font-family: $JotiOne;
    src: url(../../../assets/css/jotione-regular.ttf) format('truetype');
}

@font-face {
    font-family: $Montserrat;
    src: url(../../../assets/css/Montserrat-Regular.ttf) format('truetype');
}