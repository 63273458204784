.get-involved{
    &-header-gradient{
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;

    }
    
    
    //----custom row for get invvolved images---

    &-image-row {
        margin-top: 5rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    
        -webkit-box-orient: horizontal; 
        -moz-box-orient: horizontal;
        // box-orient: horizontal;
        flex-direction: row;
    
        -webkit-box-pack: center;
        -moz-box-pack: center;
        // box-pack: center;
        justify-content: center;
    
        -webkit-box-align: center;
        -moz-box-align: center;
        // box-align: center;  
        align-items: center;
        position: relative;
        // background-color: red;

        &-cell {
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            // box-flex: 1;
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
             
            cursor: pointer;
            // padding: 10px;
            // margin: 10px;
        
            // background-color: green;
            // border: 1px solid red;
            text-align: center;

            & > img { max-width:65%; }

            &-lable{
                font-family: $Montserrat;
                font-size: 25px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                // text-align: left;
                color: #252525;
                position: absolute;
                // max-width: 100%;
                width: 20%;
                // width: min-content;
                bottom: -20%;
                // transform: translateX(45%);//translateX(45%);
                // &-place{
                //     position: relative;
                //     width: 100%;
                //     &-position{
                //         @include absCenter;
                //         // text-align: center;
                //     }
                // }

                & > a {
                    color: #252525!important;                
                }
            }
        }
    
    }
    
    //----custom row for get involved images end--
}

.get-involved-bg-new-top{ //remove later
    top:185rem;
}

.how-can-help{
    height: 85rem;
    @media only screen and (max-device-width: 1000px) { 
        height: 99rem;    
    }    
    @include respond(tab-port){
        height: 99rem;
    }
    &-header{
        font-family: $JotiOne;
        font-size: 34px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        // text-align: left;
        color: #ececec;
        padding-top:6rem;
        padding-bottom: 3rem;
    }

    &-para{
        // mix-blend-mode: undefined;
        width: 60rem;
        margin: 0 auto;
        font-family: $Montserrat;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #ececec;
    }
}


