
.news{
    background-image: none!important;
    z-index: 0;
    &-block{
        top: 11rem;
        z-index: 0;
    }

    &-feed{
        // margin-bottom: -4px;
        border-bottom: 2px solid gray;
        margin-top: 5px;
        margin-bottom: 5px;
        &-image{
            width: 100%;
            border-radius: 10px;
        }
    }

    &-scroll-container{
        background-image: none!important;
        height: 600px;
        overflow-y: auto;
    }
}