@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
// @mixin respond($breakpoint) {
//     @if $breakpoint == phone {
//         @media only screen and (max-width: 37.5em) { @content };    //600px
//     }
//     @if $breakpoint == tab-port {
//         @media only screen and (max-width: 56.25em) { @content };     //900px
//     }
//     @if $breakpoint == tab-land {
//         @media only screen and (max-width: 75em) { @content };    //1200px
//     }
//     @if $breakpoint == big-desktop {
//         @media only screen and (min-width: 112.5em) { @content };    //1800
//     }
// }
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-device-width: 600px) { @content };    //37.5em,  600px
    }
    @if $breakpoint == tab-small {//custom for zoo, since there is previous responsive css for 768px
        @media only screen and (max-device-width: 768px) { @content };     // 56.25em,900px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-device-width: 900px) { @content };     // 56.25em,900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-device-width: 1200px) { @content };    //75em,1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-device-width: 1800px) { @content };    //112.5em,1800
    }
}

