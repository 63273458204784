*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    // @include respond(tab-land) { // width < 1200?
    //     font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    // }

    // @include respond(tab-port) { // width < 900?
    //     font-size: 50%; //1 rem = 8px, 8/16 = 50%
    // }
    
    // @include respond(big-desktop) {
    //     font-size: 75%; //1rem = 12, 12/16
    // }
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 34px;
    // margin: initial;
}

h4 {
    font-size: 25px;    
}

body {
    box-sizing: border-box;
    // padding: 3rem;

    @include respond(tab-port) {
        padding: 0;
    }
}

// ::selection {
//     background-color: $color-primary;
//     color: $color-white;
// }

