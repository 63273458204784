.footer-block{
    height: initial;
    // &-feed-section{
    //     // padding-bottom: 2.5rem;
    // }
    // @media only screen and (max-device-width: 1100px) { 
    //     // height: 8rem;//for now
    //     // width: 8rem;       
    //     // transform: translate(-20%, -50%);        
    //     // transform: translate(12%, -50%);                
    //     // height: 50rem; //keeping same for now, change later    
    
    // }; 

    @include respond(tab-port){
        // font-size: 1.8rem; 
        //height: 50rem; //keeping same for now, change later  
        height: 60rem;//quick fix for footer changes  
    }        
    &-feed-lable{
        // font-size: $font-size-large;
        // font-size: 2.4rem;
        font-size: 34px;
        margin-top: 6.6rem;

        @include respond(tab-port){
            font-size: 1.8rem; 
        }        
    }
}

.copyright-block{
    width: 100%;
    height: 5rem;
    text-align: center;
    line-height: 5rem;
    font-family: $Montserrat;
    background-color: #000;
    // font-size: $font-size-small;
    font-size: 14px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 2;
    // letter-spacing: normal;
    // text-align: center;
    color: $footer-feed-label-color;
}